import PropTypes from 'prop-types';
import React from 'react';

function WorkFilters(props) {
  const onChange = (e) => {
    props.changeFilter(e.target.value);
  };

  return (
    <>
      <div className="filters">
        <div className="filters__buttons">
          <ul>
            <li>
              <button
                onClick={() => {
                  props.changeFilter('all');
                }}
                className={props.selectedFilter === 'all' ? 'is-selected' : ''}
              >
                View all projects
                <svg xmlns="http://www.w3.org/2000/svg" width="11" height="10" viewBox="0 0 11 10">
                  <path
                    d="M 10.15436172485352 9.5 L 0.8456379771232605 9.5 L 5.5 1.037523627281189 L 10.15436172485352 9.5 Z"
                    stroke="none"
                  />
                  <path
                    d="M 5.5 2.075047492980957 L 1.691275596618652 9 L 9.308724403381348 9 L 5.5 2.075047492980957 M 5.5 0 L 11 10 L 0 10 L 5.5 0 Z"
                    stroke="none"
                    fill="#f28e1e"
                  />
                </svg>
              </button>
            </li>
            <li className={props.selectedFilter !== 'all' ? 'is-selected' : ''}>
              View by expertise
              <svg xmlns="http://www.w3.org/2000/svg" width="11" height="10" viewBox="0 0 11 10">
                <path
                  d="M 10.15436172485352 9.5 L 0.8456379771232605 9.5 L 5.5 1.037523627281189 L 10.15436172485352 9.5 Z"
                  stroke="none"
                />
                <path
                  d="M 5.5 2.075047492980957 L 1.691275596618652 9 L 9.308724403381348 9 L 5.5 2.075047492980957 M 5.5 0 L 11 10 L 0 10 L 5.5 0 Z"
                  stroke="none"
                  fill="#f28e1e"
                />
              </svg>
            </li>
          </ul>

          <ul>
            {props.sector &&
              props.sector.map((node, index) => (
                <li key={index}>
                  <button
                    onClick={() => {
                      props.changeFilter(node);
                    }}
                    className={node === props.selectedFilter ? 'is-selected' : ''}
                  >
                    {node}

                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="11"
                      height="10"
                      viewBox="0 0 11 10"
                    >
                      <path
                        d="M 10.15436172485352 9.5 L 0.8456379771232605 9.5 L 5.5 1.037523627281189 L 10.15436172485352 9.5 Z"
                        stroke="none"
                      />
                      <path
                        d="M 5.5 2.075047492980957 L 1.691275596618652 9 L 9.308724403381348 9 L 5.5 2.075047492980957 M 5.5 0 L 11 10 L 0 10 L 5.5 0 Z"
                        stroke="none"
                        fill="#f28e1e"
                      />
                    </svg>
                  </button>
                </li>
              ))}
          </ul>
        </div>

        <div className="filters__select">
          <select value={props.selectedFilter} onChange={onChange}>
            <option value="all">View all projects</option>
            {props.sector &&
              props.sector.map((node, index) => (
                <option key={index} value={node}>
                  {node}
                </option>
              ))}
          </select>

          <svg width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
            <path
              fillRule="evenodd"
              d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
            />
          </svg>
        </div>
      </div>
    </>
  );
}

WorkFilters.propTypes = {
  sector: PropTypes.array,
  changeFilter: PropTypes.func,
  selectedFilter: PropTypes.string
};

export default WorkFilters;
