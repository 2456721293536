import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import { useInView } from 'react-intersection-observer';
import { buildImageObj, getWorkUrl, slugify } from '../../lib/helpers';
import { imageUrlFor } from '../../lib/image-url';
import PortableText from '../portableText';


function WorkCard(props) {
  let hasAdded = false;
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.35
  });
  return (
      <div
      className={`work-card in-view ${inView ? 'in-view' : ''}`}
    >
      <Link to={getWorkUrl(props.slug.current)} className="site-wide">
        <div className="work-card__details grid-container grid-container--4-2 grid-container--reverse">
          <div className="grid-column copy">
            <h2>{props.title}</h2>
            {props._rawExcerpt && (
              <div className="work-card__content">
                <PortableText blocks={props._rawExcerpt} />
                <PortableText blocks={props._rawExcerptStat} />
              </div>
            )}
            <button className="btn">View full case study</button>
          </div>
          <div className="grid-column image">
            <div className="work-card__media">
              {props.cardMedia.image && props.cardMedia.image.asset && (
                <img
                  src={imageUrlFor(buildImageObj(props.cardMedia.image)).auto('format').url()}
                  alt={props.cardMedia.alt}
                />
              )}

              {props.cardMedia.video && props.cardMedia.video.asset && (
                <video className="hero__media" autoPlay loop muted playsInline>
                  <source src={props.cardMedia.video.asset.url} type="video/mp4" />
                </video>
              )}
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
}

WorkCard.propTypes = {
  title: PropTypes.string,
  slug: PropTypes.object,
  cardMedia: PropTypes.object,
  _rawExcerpt: PropTypes.array,
  selected: PropTypes.string
};

export default WorkCard;
