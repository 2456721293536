import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import WorkCard from './work-card';
import { slugify } from '../../lib/helpers';

function shuffleArray(cards, sector) {
  let filteredArray;
  let removeItems = [];

  cards.forEach((card, index) => {
    filteredArray = card.sector.filter((cardCat) => {
      const title = slugify(cardCat.title);
      return sector === title;
    });

    if (filteredArray.length > 0) {
      removeItems.push(card);
    }
  });

  cards = cards.filter(item => !removeItems.includes(item))
  return removeItems;
}

function WorkCardList(props) {
  const sect = slugify(props.sector);
  const shuffledPosts = (props.sector !== 'all' ? shuffleArray(props.nodes, sect) : props.nodes);
  // const shuffledPosts = props.nodes;
  return (
    <>
      {shuffledPosts.length > 0 && (
        <section className="work-cards section section--no-padding">
          {shuffledPosts.map((node, index) => (
              <Fragment key={index}>
                <WorkCard
                  {...node}
                  selected={props.selected}
                  />
              </Fragment>
            ))}
        </section>
      )}
    </>
  );
}

WorkCardList.propTypes = {
  nodes: PropTypes.array,
  sector: PropTypes.string,
  selected: PropTypes.string
};

export default WorkCardList;
